<template>
    <div>
        <div v-if="!isLoading">
            <div
                v-if="dataFound"
            >
                <b-card no-body>
                    <b-card-header>
                        <div>
                            <b-card-title class="mb-1">
                                General
                            </b-card-title>
                            <b-card-sub-title>Line chart for monthly shipment quantity to Amazon FBA warehouses</b-card-sub-title>
                        </div>
                    </b-card-header>

                    <!-- chart -->
                    <b-card-body>
                        <chartjs-line-chart
                            :height="400"
                            :plugins="plugins"
                            :data="tableData"
                        />
                    </b-card-body>
                </b-card>
                <b-row>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-with-line-chart
                            icon="TruckIcon"
                            color="info"
                            :statistic="totalCompletedShipments"
                            statistic-title="Completed shipments"
                            :chart-data="completedShipmentsDataSmall"
                        />
                    </b-col>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-with-line-chart
                            icon="Link2Icon"
                            color="success"
                            :statistic="totalItemsShipped"
                            statistic-title="Items shipped"
                            :chart-data="itemsShippedDataSmall"
                        />
                    </b-col>
                    <b-col
                        md="4"
                        sm="6"
                    >
                        <statistic-card-with-line-chart
                            icon="PackageIcon"
                            color="primary"
                            :statistic="totalShipments"
                            statistic-title="Shipments"
                            :chart-data="shipmentsDataSmall"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col
                        lg="3"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="MapIcon"
                            statistic="Germany"
                            statistic-title="Most shipped country (EU region)"
                        />
                    </b-col>
                    <b-col
                        lg="3"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="Link2Icon"
                            color="success"
                            :statistic="totalItemsShippedAverage.toFixed(0)"
                            statistic-title="Average count of items per shipment"
                        />
                    </b-col>
                    <b-col
                        lg="3"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="TagIcon"
                            color="danger"
                            :statistic="totalMSKUsAverage.toFixed(0)"
                            statistic-title="Average MSKUs per shipment"
                        />
                    </b-col>
                    <b-col
                        lg="3"
                        sm="6"
                    >
                        <statistic-card-horizontal
                            icon="BoxIcon"
                            color="warning"
                            :statistic="totalBoxesAverage.toFixed(0)"
                            statistic-title="Average box amount per shipment"
                        />
                    </b-col>
                </b-row>
            </div>
            <div
                v-else
            >
                <b-alert
                    show
                    variant="info"
                >
                    <h4
                        class="alert-heading"
                    >
                        Whoops!
                    </h4>
                    <div
                        class="alert-body"
                    >
                        Sorry, but we didn't find any data that we could process for this dashboard!<br>
                        Most likely the reason is that you do not have your Linnworks connected together with Fulfillin.
                    </div>
                </b-alert>
            </div>
        </div>
        <div
            v-else
            class="row mx-auto justify-content-center align-items-center flex-column"
        >
            <b-spinner
                style="width: 2rem; height: 2rem; margin-top: 20px;"
                variant="primary"
            />
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
    BCardTitle,
    BSpinner,
    BRow,
    BCol,
    BAlert,
} from 'bootstrap-vue'
import Axios from 'axios'
import { $themeColors } from '@themeConfig'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ChartjsLineChart from '../../components/Charts/ChartjsLineChart.vue'

const chartColors = {
    primaryColorShade: '#836AF9',
    yellowColor: '#ffe800',
    successColorShade: '#28dac6',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardSubTitle,
        BCardTitle,
        BSpinner,
        BRow,
        BCol,
        BAlert,
        StatisticCardWithLineChart,
        StatisticCardHorizontal,
        ChartjsLineChart,
    },
    data() {
        return {
            isLoading: true,
            dataFound: false,
            shipmentsDataSmall: [],
            itemsShippedDataSmall: [],
            completedShipmentsDataSmall: [],
            totalShipments: 0,
            totalCompletedShipments: 0,
            totalBoxes: 0,
            totalMSKUs: 0,
            totalItemsShipped: 0,
            totalBoxesAverage: 0,
            totalMSKUsAverage: 0,
            totalItemsShippedAverage: 0,
            tableData: [],
            plugins: [
                {
                    beforeInit(chart) {
                        /* eslint-disable func-names, no-param-reassign */
                        chart.legend.afterFit = function () {
                            this.height += 20
                        }
                        /* eslint-enable */
                    },
                },
            ],
        }
    },
    mounted() {
        this.setDataForTable()
    },
    methods: {
        setDataForTable() {
            const shipmentsData = []
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments`,
                method: 'POST',
                data: {
                    page: 1,
                    take: 1000,
                    search: '',
                    order_by: [
                        {
                            col: 'created_at',
                            dir: 'asc',
                        },
                    ],
                },
            }).then(resp => {
                shipmentsData.push(resp.data)
                this.dataFound = true

                const possibleDates = []
                const dateValues = []
                const dateValuesPL = []
                const dateValuesDE = []
                const shipmentsByDate = []
                const itemsShippedDateValues = []
                const completedShipmentsDateValues = []
                setTimeout(() => {
                    shipmentsData[0].data.forEach(value => {
                        const date = new Date(value.created_at.text)
                        const month = (date.getMonth() + 1).toString().padStart(2, '0')
                        if (possibleDates.indexOf(`${date.getFullYear()}-${month}`) === -1) possibleDates.push(`${date.getFullYear()}-${month}`)
                        this.totalBoxes += value.boxes.text
                        this.totalMSKUs += value.mskus.text
                        this.totalItemsShipped += value.shipped.text
                    })
                    possibleDates.forEach(value => {
                        dateValues.push(shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes(value)).length)
                        shipmentsByDate.push(shipmentsData[0].data.filter(shipment => shipment.created_at.text.includes(value)))
                    })
                    shipmentsByDate.forEach(value => {
                        let itemsShipped = 0
                        let finishedShipments = 0
                        let PLShipments = 0
                        let DEShipments = 0
                        value.forEach(_value => {
                            itemsShipped += _value.shipped.text
                            if (_value.status.text.includes('Completed')) finishedShipments += 1
                            if (_value.country.text.includes('PL')) PLShipments += 1
                            if (_value.country.text.includes('DE')) DEShipments += 1
                        })
                        itemsShippedDateValues.push(itemsShipped)
                        completedShipmentsDateValues.push(finishedShipments)
                        dateValuesPL.push(PLShipments)
                        dateValuesDE.push(DEShipments)
                    })
                    this.totalShipments += shipmentsData[0].data.length
                    this.totalBoxesAverage = this.totalBoxes / this.totalShipments
                    this.totalMSKUsAverage = this.totalMSKUs / this.totalShipments
                    this.totalItemsShippedAverage = this.totalItemsShipped / this.totalShipments
                    this.totalCompletedShipments = shipmentsData[0].data.filter(shipment => shipment.status.text.includes('Completed')).length
                    possibleDates.push('2021-03')
                    this.shipmentsDataSmall = [{ name: 'Shipments', data: dateValues }]
                    this.completedShipmentsDataSmall = [{ name: 'Completed', data: completedShipmentsDateValues }]
                    this.itemsShippedDataSmall = [{ name: 'Shipped', data: itemsShippedDateValues }]
                    const o = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 })
                    this.totalItemsShipped = o.format(this.totalItemsShipped).toLowerCase()
                    this.totalCompletedShipments = o.format(this.totalCompletedShipments).toLowerCase()

                    this.tableData = {
                        labels: possibleDates,
                        datasets: [
                            {
                                data: dateValues,
                                label: 'Total shipments',
                                borderColor: chartColors.warningLightColor,
                                lineTension: 0.5,
                                pointStyle: 'circle',
                                backgroundColor: chartColors.warningLightColor,
                                fill: false,
                                pointRadius: 1,
                                pointHoverRadius: 5,
                                pointHoverBorderWidth: 5,
                                pointBorderColor: 'transparent',
                                pointHoverBorderColor: $themeColors.white,
                                pointHoverBackgroundColor: chartColors.warningLightColor,
                                pointShadowOffsetX: 1,
                                pointShadowOffsetY: 1,
                                pointShadowBlur: 5,
                                pointShadowColor: chartColors.tooltipShadow,
                            },
                        ],
                    }
                    this.isLoading = false
                }, 5100)
            }).catch(() => {
                this.isLoading = false
                this.dataFound = false
            })
        },
    },
}
</script>
